// packages
import { useLayoutEffect } from "react";

// components
import AboutUsList from "../components/AboutUsList";
import TopSection2 from "../components/TopSection2";

// images
import AboutUsBg from "../images/56_cover.jpg";

const AboutUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <TopSection2
        title="56 years of SOS"
        backgroundImage={AboutUsBg}
        bgImageGradient={0}
      />
      <AboutUsList />
    </>
  );
};

export default AboutUs;