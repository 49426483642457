const TopSection = ({
    title,
    backgroundImage,
    quote,
    author,
    bgImageGradient = 0,
}) => {
    var backgroundImageClass = ``;
    if (bgImageGradient > 0) {
        backgroundImageClass += `linear-gradient( rgba(0, 0, 0, ${bgImageGradient}), rgba(0, 0, 0, ${bgImageGradient}) ), `;
    }
    backgroundImageClass += `url(${backgroundImage})`;

    return (
        <div
            data-aos="fade"
            className="w-full flex flex-col md:flex-row pt-64 pb-12 justify-between items-start md:items-center px-8 gap-12 md:px-28 md:pt-[55vh] md:pb-0 bg-no-repeat bg-cover bg-center h-[80vh] md:h-[95vh]"
            style={{
                backgroundImage: `${backgroundImageClass}`,
            }}
        >
            {title && (
                <span
                    data-aos="fade-right"
                    data-aos-delay={500}
                    // ?className="text-white bg-[#c4c4c466] backdrop-blur-sm text-3xl uppercase px-4 py-3 rounded-md tracking-wide font-semibold"
                    className="title-textShadow text-white text-3xl md:text-5xl font-bold tracking-wide drop-shadow-md"
                >
                    {title}
                </span>
            )}
            {quote && (
                <span
                    data-aos="fade-left"
                    data-aos-delay={500}
                    className="md:w-[500px] font-medium text-white flex flex-col gap-3 rounded-lg bg-[#1e9ad6] px-6 py-7 md:leading-8 text-base md:text-2xl md:translate-y-1/2"
                >
                    <span className="">“{quote}”</span>
                    <div className="text-right text-sm md:text-xl">
                        - {author}
                    </div>
                </span>
            )}
        </div>
    );
};

export default TopSection;
