import pedagogy from "../images/pedagogy-cover.jpg";
import Pedagogy from "../images/stem-2.jpeg";
import Activities from "../images/ncc_cover.jpg";
import AwardsAccolades from "../images/bgImage.jpg";
import ParentEngagement from "../images/alumni.jpeg";

import { Link } from "react-router-dom";

const InstituteActivities = () => {
    const data = [
        {
            title: "Pedagogical Approach",
            image: pedagogy,
            description: `A variety of teaching-learning methodologies, integrated with technology, are being used to give 
            students a holistic education making them independent, life-long learners and above all responsible 
            citizens of the globe.`,
            path: "/pedagogy",
            bgGradient: 0.5,
        },
        {
            title: "Beyond Curriculum",
            image: Pedagogy,
            description: `Varied clubs and houses help to reach and accomplish the vision and mission that the school stands 
      by. The values and ethos of the school are promoted and imbibed through these clubs and houses`,
            path: "/beyound-curriculum",
            bgGradient: 0.5,
        },
        {
            title: "NCC, School Band and Scounts & Guides",
            image: Activities,
            description: `Discipline and leadership qualities are inculcated in a large number of students by giving them training
      under the NCC & Scout and Guides, making them capable, sensitized to work for the betterment of 
      society and for enhancing their lifestyle and leadership qualities`,
            path: "/activities",
            bgGradient: 0.5,
        },
        {
            title: "Illustrious Alumni",
            image: ParentEngagement,
            description: `A large number of bright and capable scholars, having graduated from the Hermann Gmeiner Group 
      of schools, have distinguished themselves by means of their extraordinary achievements. We have 
      brilliant alumni history. In their respective fields, these alumni have excelled in their profession.`,
            path: "/alumni",
            bgGradient: 0.55,
        },
        {
            title: "Achievements & Laurels",
            image: AwardsAccolades,
            description: `Awards are always a source of pride and also a reminder to work together and bring the glory back 
      every time. They are also an inspiration for our coming generations to look up to and give their best in 
      every field they work hard for.`,
            path: "/awards",
            bgGradient: 0.5,
        },
    ];
    return (
        <div
            data-aos="fade"
            className="w-full mt-4 grid grid-flow-row auto-rows-fr md:grid-flow-col md:auto-cols-fr text-white"
        >
            {data.map((item, index) => {
                return (
                    <Link
                        to={item.path}
                        data-aos="fade"
                        data-aos-delay={(index + 1) * 200}
                        className="bg-no-repeat bg-top bg-cover "
                        style={{
                            backgroundImage: `linear-gradient( rgba(0, 0, 0, ${item.bgGradient}), rgba(0, 0, 0, ${item.bgGradient}) ), url(${item.image})`,
                        }}
                        key={index}
                    >
                        <div
                            data-aos="fade"
                            data-aos-delay={(index + 1) * 200 + 100}
                            className="flex flex-col gap-8 px-4 pt-32 pb-40 items-center h-full duration-300 hover:bg-[#1e9ad680] text-center"
                        >
                            <div className="h-[4px] w-24 bg-[#1e9ad6]"></div>
                            <div className=" h-28 font-bold text-xl drop-shadow-sm">
                                {item.title}
                            </div>

                            <p className=" h-3/5 text-center text-sm font-[400] drop-shadow-sm">
                                {item.description}
                            </p>

                            <Link
                                to={item.path}
                                className="text-[#ffd077] drop-shadow-sm text-sm font-[400] underline-offset-1 hover:underline hover:scale-105 duration-200"
                            >
                                Learn more
                            </Link>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

export default InstituteActivities;
