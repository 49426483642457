import React from "react";



const Booklist = () => {
    const pdfFiles = [
        { name: 'Booklist, Hermann Gmeiner School Bhopal', url: '../booklist/Bhopal School.pdf'},
        { name: 'Booklist, Hermann Gmeiner School Bhimtal', url: '../booklist/Bhimtal School.pdf'},
        { name: 'Booklist, Hermann Gmeiner School Anangpur', url: '../booklist/Anangpur Primary School.pdf'},
        { name: 'Booklist, Hermann Gmeiner School Palla Nuh', url: '../booklist/Booklist Palla Nuh.pdf'},
        { name: 'Booklist, Hermann Gmeiner School Varanasi', url: '../booklist/Booklist-Varanasi School.pdf'},
        { name: 'Booklist, Hermann Gmeiner School Jammu', url: '../booklist/Booklist Jammu School.pdf'},
        { name: 'Booklist, Hermann Gmeiner School Faridabad', url: '../booklist/Booklist Faridabad School.pdf'}
      
      ];
  
 
  return (
    <div className="home_main">
      {/* <Navbar isScrolled={true} handleClick={handleClick} /> */}
      <div className="pdf-container">
      <table className="pdf-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Filename</th>
            <th>Link To Documents</th>
          </tr>
        </thead>
        <tbody>
          {pdfFiles.map((file, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{file.name}</td>
              <td>
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      
    </div>
  );
};

export default Booklist;
