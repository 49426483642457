const Mission = () => {
    return (
        <div data-aos="fade" className="w-full p-8 md:p-20">
            <div
                data-aos="fade"
                data-aos-delay={300}
                className="border-box text-white bg-[#1e9ad6] rounded-3xl p-8 md:p-12"
            >
                <div
                    data-aos="fade-left"
                    data-aos-delay={500}
                    className="font-bold text-3xl mb-4"
                >
                    Our Mission
                </div>
                <p
                    data-aos="fade-left"
                    data-aos-delay={700}
                    className="text-sm md:text-xl"
                >
                    We strive to educate all students to the highest levels of
                    academic achievement, to enable them to reach and expand
                    their potential, and to prepare them to become productive,
                    responsible, ethical, creative and compassionate members of
                    society.
                </p>
                <p
                    data-aos="fade-left"
                    data-aos-delay={700}
                    className="text-sm md:text-xl mt-4"
                >
                    We are dedicated to a continuing tradition of excellence in
                    an ever-changing world. Within a safe and supportive
                    environment, we aim to provide relevant, high-quality
                    education by building a more inclusive curriculum,
                    addressing a range of learning styles and offering a wide
                    array of diversity efforts and programs thereby ensuring
                    holistic development of our learners while preparing them
                    for future endeavours.
                </p>
            </div>
        </div>
    );
};

export default Mission;
