import HermanPhoto from "../images/Rectangle 39.jpg";

const HermannGmeiner = () => {
  return (
    <div className="w-full bg-[#f2f2f2] p-8 md:p-20 flex flex-col md:flex-row gap-12">
      <div data-aos="fade-right" className="rounded">
        <img className="w-full" src={HermanPhoto} alt="Hermann Gmeiner" />
      </div>
      <div className="flex flex-col md:w-4/5">
        <div
          data-aos="fade-left"
          data-aos-delay={300}
          className="text-3xl font-bold mb-2"
        >
          Hermann Gmeiner
        </div>
        <div
          data-aos="fade-left"
          data-aos-delay={500}
          className="text-[#9e9e9e] font-semibold text-2xl mb-3"
        >
          Philanthropist
        </div>
        <div className="text-[#595959]">
          <div
            data-aos="fade-left"
            data-aos-delay={700}
            className="font-semibold text-base italic"
          >
            "Every big thing in the world only comes true, when somebody does
            more than he has to do.”
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay={900}
            className="text-sm md:text-base"
          >
            Born and brought up in Austria, Hermann Gmeiner wanted every child to grow up in a loving family. Having served as a soldier, he experienced the repercussions of World War II, which eventually made him confront the sufferings of many parentless and homeless children. With barely 40 US dollars, Hermann Gmeiner established SOS Children's Village Association in 1949, and in the same year, the foundation stone was laid for the first SOS Children's Village in Austria. He loved children and was deeply committed to helping them by building loving families and supportive communities. Today, SOS Children’s Villages is active in more than 135 countries and territories, helping thousands of children each year. Children are brought up in a home-like environment with all kinds of facilities, ensuring wholesome child development.<br/><br/> He envisaged holistic schools
            and centres for the education and overall development of children.
            In his memory, Hermann Gmeiner Group of schools, B.Ed. and Nursing
            colleges and Vocational Training Institutes were founded.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HermannGmeiner;