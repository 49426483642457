// packages
import { useLayoutEffect } from "react";

// containers
import TopSection2 from "../components/TopSection2";
import PageListSection from "../components/PageListSection";
import Phone from "../images/phone.png";
import PedagogiSkill from "./PedagogiSkill";
// images
import CareersCover from "../images/careers_cover.jpg"
import topicon from "../images/pedagogyTopIcon.svg";

const NCC = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });


    return (
        <>
            {/* <TopSection2
        // title="Pedagogy"
        backgroundImage={CareersCover}
        bgImageGradient={0}
      // quote="Children must be taught how to think, not what to think."
      // author="Margaret Mead"
      /> */}

            <div className="w-full h-full px-12 md:px-20 py-6 snap-center">
                <div className="flex">
                    {/* <img
            className="pedagogi_image"
            src={topicon}
            alt=""
            style={{ width: "80px" }}
          /> */}
                    <h1 className="text-4xl mt-4 font-semibold text-[#1fdc00d2]">

                       NCC Scoutes and Guides
                    </h1>
                </div>
                <div className="flex gap-2">
                    <div>
                        <div className="text-[#000000b8]  text-1xl mx-6 my-12">

                        </div>
                        <div className="text-[#000000c4]  mx-6 my-8">

                        To inculcate discipline and leadership qualities, a large number of students are being trained under the NCC & Scout and Guides for making them capable, sensitized to work for the betterment of society and for enhancing their lifestyle and leadership qualities.
                        </div>
                        {/* <div className=" my-15 flex justify-center">
              <img className="mb-16" src={p1} />
            </div> */}
                        <div className="text-[#000000c4] font-medium mx-6 my-4">


                            {/* <br /> */}
                        </div>

                        <div className="text-[#000000c4]  mx-6 my-4 ">
                            {/* <h2 className="font-semibold">Areas of Responsibility:</h2> */}

                            {/* <h3 className="font-semibold">Position Purpose (Mission):</h3> */}
                            
                            <h3></h3>

                            <br />
                            <h4 className="font-semibold">NCC</h4>

                            <div className="text-[#000000c4]  mx-6 my-4">
                            To establish the quality of leadership, to enhance their grace and to give all the students the pride and confidence, we give all the interested candidates special NCC training. Tough yet specialised physical and mental training along with fun sessions help the participants to develop and relax simultaneously. Specially trained teachers and officers often take various lectures and seminars to help and guide the students.
                                 </div>
                            <br/>
                            <h4 className="font-semibold">
                                School Band</h4>
                                <div className="text-[#000000c4]  mx-6 my-4">
                                Music has always proven to be one of the best therapies and to train the students in all aspects, our school has a special band. Training and understanding of various musical instruments, importance of teamwork and knowledge of musical notes are provided to the students. The school band often performs in different events and functions of the schools for which the equipment is both provided as well as allowed to bring on their own.
                                 </div>

                            <br />
                            <h4 className="font-semibold">Scoutes and Guides</h4>
                            <div className="text-[#000000c4]  mx-6 my-4">
                            All eligible and interested students are given fun but important teachings of scouts and guides. It helps them grow physically, mentally, socially and intellectually. Uniforms and other requirements that we provide are a must to bring in the quality of discipline among them. All of them are expected to learn the rules and regulations by heart and implement the same in their actual lives, hence making them stronger and more confident.
                                 </div>






                            <br />


                        </div>











                    </div>
                </div>
            </div>



            {/* <PageListSection data={data} /> */}
        </>
    );
};

export default NCC; 