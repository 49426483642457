// packages
import { Fragment, useLayoutEffect, useRef, useState } from "react";

// components
import PhotoGallery from "../components/PhotoGallery";
import InstitutesAndSchools from "../components/InstitutesAndSchools";
import HermannGmeiner from "../components/HermannGmeiner";
import Mission from "../components/Mission";
import InstituteValues from "../components/InstituteValues";
import InstituesList from "../components/InstitutesList";
import InstituteActivities from "../components/InstituteActivities";
import Journey from "../components/Journey";
import InstituteActivities2 from "../components/InstituteActivities2";
import HomeMainSection from "../components/HomeMainSection";
import HomeModal from "../components/HomeModal";
import ResultMoving from "../components/ResultMoving";

// images

const HomePage = () => {
    const [isModal, setIsModal] = useState(true);
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    const handleClose = () => {
        setIsModal(false);
    };
    const cancelButtonRef = useRef(null);
    return (
        <Fragment>
            <HomeMainSection />
            {/* <ResultMoving /> */}
            <InstituesList />
            <InstituteActivities />
            {/* <Mission /> */}
            <InstituteValues />
            <Journey />
            {/* <InstituteActivities2 /> */}
            {/* <InstitutesAndSchools /> */}
            {/* <PhotoGallery /> */}
            <HomeModal
                show={isModal}
                onHide={handleClose}
                cancelButtonRef={cancelButtonRef}
            />
        </Fragment>
    );
};

export default HomePage;
