// packages
import { useLayoutEffect } from "react";

// components
import TopSection from "../components/TopSection";
import PageListSection from "../components/PageListSection";

// images
import ActivitesBgPhoto from "../images/ncc_cover.jpg";
import NCC from "../images/ncc_1.jpg";
import Scouts from "../images/scout_1.jpg";
import SchoolBand from "../images/band_1.jpg";
import ncc_icon from "../images/ncc-icon.jpg";
const ActivitiesPage = () => {
  const data = [
    {
      icon: ncc_icon,
      heading: "NCC, School Band and Scouts & Guides",
      image: null,
      content:
        "To inculcate discipline and leadership qualities, a large number of students are being trained under the NCC & Scout and Guides for making them capable, sensitized to work for the betterment of society and for enhancing their lifestyle and leadership qualities.",
      isIntroPara: true,
      padding: "0rem",
      headerStripe: false,
      headingColor: "#ff8c22",
    },
    {
      heading: "NCC",
      image: NCC,
      content: `To establish the quality of leadership, to enhance their grace and to give all the 
                      students the pride and confidence, we give all the interested candidates special 
                      NCC training. Tough yet specialised physical and mental training along with fun 
                      sessions help the participants to develop and relax simultaneously. Specially 
                      trained teachers and officers often take various lectures and seminars to help 
                      and guide the students.`,
    },
    {
      heading: "School Band",
      image: SchoolBand,
      content: `Music has always proven to be one of the best therapies and to train the 
                        students in all aspects, all of our schools have a special school band. 
                        Training and understanding of various musical instruments, importance of 
                        teamwork and knowledge of musical notes are provided to the students. The 
                        school band often performs in different events and functions of the schools 
                        for which the equipment is both provided as well as allowed to bring on 
                        their own.`,
    },
    {
      heading: "Scouts & Guides",
      image: Scouts,
      content: `All eligible and interested students are given fun but important teachings 
                      of scouts and guides. It helps them grow physically, mentally, socially and 
                      intellectually. Uniforms and other requirements that we provide are a must 
                      to bring in the quality of discipline among them. All of them are expected 
                      to learn the rules and regulations by heart and implement the same in their 
                      actual lives, hence making them stronger and more confident.`,
    },

    // {
    //     heading: "Sports Club",
    //     image: SportsClub,
    //     // content: `Sports club works in promoting physical and mental fitness and a healthy life style among the students. The club conducts sports meet – both inter-house and inter school competition. It helps facilitate the process of building concentration and confidence levels of students. Students participate in different kinds of physical activity, that make them active and agile.`,
    //     content: "Sports club works on promoting physical and mental fitness and a healthy life style among the students. The club conducts sports meets – both inter-house and inter-school competitions which help facilitate the process of building concentration and confidence levels among students. Students participate in different kinds of physical activity, which makes them active and agile."
    // },
    // {
    //     heading: "Inter-School Competitions",
    //     image: InterHgsComp,
    //     // content: `The school conducts healthy and encouraging competition for all the kids to showcase their best and always improve for the better. Inner and inter-school competition is a good way to keep everyone cheerful and determined. Various inter-school competitions like 'Samanway' conducted by the English literary club and a mathematics competition were conducted in 2021 for students of ours and other nearby schools to develop the healthy attitude of competition and give your best.`,
    //     content:
    //         "Our schools conduct healthy and encouraging competitions for all the students to engage and improve for better. Intra and inter-school competitions are a good way to keep everyone cheerful and determined. Various inter-school competitions like 'Samanway' conducted by the English Literary club and a mathematics competition were held in 2021 for students of ours as well as other nearby schools to develop a healthy competitive attitude. Other clubs like Science club, Arts club, Literary club, etc. often conduct science, literature, sports and art & craft competitions for all students to showcase their talent.",
    // },
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <TopSection
        // title="Co-Curricular Activities"
        backgroundImage={ActivitesBgPhoto}
        bgImageGradient={0}
        // quote="If we wish for our nature to be free and joyous, we should bring our activities into the same order."
        // author="Vinoba Bhave"
      />
      <PageListSection data={data} />
    </>
  );
};

export default ActivitiesPage;
