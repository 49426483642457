// packages
import { useLayoutEffect } from "react";

// components
import AlumniList from "../components/AlumniList";
import TopSection2 from "../components/TopSection2";

// images
import AboutUsBg from "../images/our_board_cover.jpg";
import RakeshJinsi from "../images/rakesh_jinsi.png";
import SudhaShastri from "../images/sudha_shastri.jpg";
import AtulBakshi from "../images/atul_bakshi.jpeg";
import AtindraBattacharya from "../images/atindra_battacharya.jpg";
import RaghuKailas from "../images/raghu_kailas.jpg";
import NandiniAtal from "../images/nandini_atal.png";
import RajinderSharma from "../images/rajinder_sharma.png";
import SeemaChopra from "../images/seema_chopra.jpg";
import AshisRay from "../images/ashis_ray.jpg";
import SumantaKar from "../images/sumanta_kar.jpg";
import MRamachandran from "../images/m_ramachandran.jpg";
import BharatDeva from "../images/bharat_deva.jpg";
import RajeevGrover from "../images/rajeev_grover.jpg";

const OurBoardPage = () => {
  const data = [
    {
      name: "Mr. Rakesh Jinsi",
      image: RakeshJinsi,
      description: "President",
    },
    {
      name: "Mrs. Sudha Shastri",
      image: SudhaShastri,
      description: "Vice President",
    },
    {
      name: "Mr. Rajeev Kumar Grover",
      image: RajeevGrover,
      description: "Treasurer",
    },
    {
      name: "Mr. Bharat Bhushan Deva",
      image: BharatDeva,
      description: "Member",
    },
    {
      name: "Mr. M. S. Ramachandran",
      image: MRamachandran,
      description: "Member",
    },
    {
      name: "Mr. Sumanta Kar",
      image: SumantaKar,
      description: "Secretary General",
    },
    {
      name: "Mr. Ashis Kumar Ray",
      image: AshisRay,
      description: "Member",
    },
    {
      name: "Mrs. Seema Anand Chopra",
      image: SeemaChopra,
      description: "Member",
    },
    {
      name: "Mr. Rajinder Sharma",
      image: RajinderSharma,
      description: "Member",
    },
    {
      name: "Mrs. Nandini Atal",
      image: NandiniAtal,
      description: "Member",
    },
    {
      name: "Mr. Raghu Kailas",
      image: RaghuKailas,
      description: "Member",
    },
    {
      name: "Mr. Atindra Nath Battacharya",
      image: AtindraBattacharya,
      description: "Member",
    },
    {
      name: "Lt Col Atul Bakshi (Retd)",
      image: AtulBakshi,
      description: "Member",
    },
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <TopSection2
        title="Our Board"
        backgroundImage={AboutUsBg}
        bgImageGradient={0}
      />
      <AlumniList data={data} />
    </>
  );
};

export default OurBoardPage;
