import SchoolPhoto from "../images/schools/Bhopal.jpg";

const InstitutesList = () => {
    return (
        <div
            data-aos="fade"
            className="w-full py-28 px-5 md:px-20 text-white flex flex-col items-center text-center"
            style={{
                background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${SchoolPhoto})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "top",
            }}
            id="schools-and-institions"
        >
            <div
                data-aos="fade-up"
                data-aos-delay={300}
                className="title-textShadow font-bold md:leading-normal text-3xl md:text-5xl text-center mb-12 drop-shadow-md"
            >
                {/* Hermann Gmeiner Schools and Training Institutions */}
                Hermann Gmeiner Educational Institutions
            </div>
            {/* <div
        data-aos="fade-up"
        data-aos-delay={500}
        className="title-textShadow text-xl md:text-3xl mb-12 text-center drop-shadow-md"
      >
        ESTD 1986
      </div> */}
            <div className="flex flex-col lg:grid lg:grid-cols-3 justify-around items-center lg:items-stretch w-full gap-8 md:gap-12">
                <Card
                    title="Senior Secondary CBSE Schools"
                    delay={700}
                    schoolList={[
                        {
                            name: "Hermann Gmeiner School, Faridabad",
                            link: "http://hermanngmeinerschoolfaridabad.org/",
                        },
                        {
                            name: "Hermann Gmeiner School, Bhimtal (Day-cum Boarding School)",
                            link: "http://hermanngmeinerschoolbhimtal.org/",
                        },
                        {
                            name: "Hermann Gmeiner School, Jammu",
                            link: "http://hermanngmeinerschooljammu.org/",
                        },
                        {
                            name: "Hermann Gmeiner School, Varanasi",
                            link: "http://hermanngmeinerschoolvaranasi.org/",
                        },
                    ]}
                />
                <Card
                    title="State Boards Schools"
                    delay={800}
                    schoolList={[
                        {
                            name: "Hermann Gmeiner School, Bhopal (Sr. Secondary)",
                            link: "http://hermanngmeinerschoolbhopal.org/",
                        },
                        {
                            name: "Hermann Gmeiner School, Palla Nuh (Sr. Secondary)",
                            link: "http://hermanngmeinerschoolpallanuh.org",
                        },
                        {
                            name: "Hermann Gmeiner School, Bhuj (Secondary)",
                            link: "http://hermanngmeinerschoolbhuj.org",
                        },
                        {
                            name: "Hermann Gmeiner School, Anangpur (Primary)",
                            link: "http://hermanngmeinerschoolanangpur.org",
                        },
                    ]}
                />
                <Card
                    title="Institutions"
                    delay={900}
                    schoolList={[
                        {
                            name: "SOS Nursing School, Anangpur",
                            link: "https://sosnursingschool.org",
                        },
                        {
                            name: "Hermann Gmeiner Degree College, Bhimtal",
                            link: "https://hermanngmeinerdegreecollege.org/",
                        },
                        // {
                        //     name: "Vocational Training Centre, Nizamuddin, Delhi",
                        //     link: "https://hermanngmeinervocationaltraininginstitutes.org/nizamudin",
                        // },
                        // {
                        //     name: "Vocational Training Centre, Raipur, Chhattisgarh",
                        //     link: "https://hermanngmeinervocationaltraininginstitutes.org/raipur",
                        // },
                        // {
                        //     name: "Vocational Training Centre, Bhakatpura",
                        //     link: "https://hermanngmeinervocationaltraininginstitutes.org/bhakatpara",
                        // },
                    ]}
                />
            </div>
        </div>
    );
};

const Card = ({ title, schoolList, delay }) => {
    return (
        <div
            data-aos="fade"
            data-aos-delay={delay}
            className="flex flex-col items-center gap-4 bg-[#F54D17] bg-opacity-60 border rounded-2xl p-8 md:py-8 px-12 backdrop-blur"
        >
            <h2 className="font-semibold my-3 text-center drop-shadow-md">
                {title}
            </h2>
            <div>
                <ol className="flex flex-col decoration-1 no-underline  items-start justify-start gap-4 md:gap-6 text-sm">
                    {schoolList.map((school, index) => {
                        return (
                            <a
                                href={school.link}
                                className="hover:underline hover:font-semibold "
                                target="_blank"
                            >
                                <li
                                    key={index}
                                    className="list-disc text-left pl-2 drop-shadow-md w-full"
                                >
                                    {school.name}
                                </li>
                            </a>
                        );
                    })}
                </ol>
            </div>
        </div>
    );
};

export default InstitutesList;
