// packages
import { useLayoutEffect } from "react";

// containers
import TopSection2 from "../components/TopSection2";
import PageListSection from "../components/PageListSection";
import Phone from "../images/phone.png";
import PedagogiSkill from "./PedagogiSkill";
// images
import CareersCover from "../images/careers_cover.jpg"
import topicon from "../images/pedagogyTopIcon.svg";

const NEP = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });


    return (
        <>
            {/* <TopSection2
        // title="Pedagogy"
        backgroundImage={CareersCover}
        bgImageGradient={0}
      // quote="Children must be taught how to think, not what to think."
      // author="Margaret Mead"
      /> */}

            <div className="w-full h-full px-12 md:px-20 py-6 snap-center">
                <div className="flex">
                    {/* <img
            className="pedagogi_image"
            src={topicon}
            alt=""
            style={{ width: "80px" }}
          /> */}
                    <h1 className="text-4xl mt-4 font-semibold text-[#1fdc00d2]">
NEP 2020 Ready School
                    </h1>
                </div>
                <div className="flex gap-2">
                    <div>
                        <div className="text-[#000000b8]  text-1xl mx-6 my-12">

                        </div>
                        <div className="text-[#000000c4]  mx-6 my-8">


                        </div>
                        {/* <div className=" my-15 flex justify-center">
              <img className="mb-16" src={p1} />
            </div> */}
                        <div className="text-[#000000c4] font-medium mx-6 my-4">


                            {/* <br /> */}
                        </div>

                        <div className="text-[#000000c4]  mx-6 my-4 ">
                            {/* <h2 className="font-semibold">Areas of Responsibility:</h2> */}

                            {/* <h3 className="font-semibold">Position Purpose (Mission):</h3> */}
                            {/* Vocational subjects include more hands-on training and less of the traditional academic learning. We at Hermann Gmeiner School focus on integrating art with the curriculum for all the students to appreciate the aesthetics and visual performing arts. Practical or first-hand knowledge about the market or trade is a very essential part of the vocational training that we provide to the learners.We always prioritize our students and for that we offer differentiated teaching-learning activities as well as personalized learning. All the varied Skill and Vocational subjects are included and taught at our institution. A team of dignified and intellectual teachers is always on the stand to help the students master the subjects.Emulating the skill-based model of education as per NEP 2020 and CBSE guidance, we have also introduced the skill subjects at the secondary level. Choosing any one Skill subject at this level can help the child to pursue what truly interests or pleases him or her. This liberty promotes a sense of self-esteem in accepting one’s talents and strengths. */}


                            
                            <h3></h3>


                            <h4 className="font-semibold">Our Curriculum aims to</h4>
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li>Encourage students to think critically and solve problems through project-based learning</li>
                                <li>Seamlessly integrate art into the curriculum to enhance the appreciation for aesthetic visual and performing arts.</li>
                                <li>Foster holistic development through sports, i.e. Health and Physical Education.</li>
                                <li>Offer activities for curricular and co-curricular enrichment via clubs and work education.</li>
                                <li>Engage students in addressing real-world problems and issues important to humanity to lead change.</li>
                                <li>Provide differentiated teaching-learning activities to personalize learning.</li>
                            </ul>
                            <br/>
                            <h4 className="font-semibold">
                                
Key Features of the Curriculum</h4>
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li>Individualized attention – low student-teacher ratio

                                </li>
                                <li>Stimulating learning environment</li>
                                <li>Emphasis on all-round development and co-curricular activities</li>
                                <li>Integration of ICT and Innovation in the Curriculum</li>
                                <li>Parental Engagement Programmes</li>
                                <li>Eco-Literacy Curriculum</li>
                                <li>360-degree assessment approach</li>
                                <li>Skill-based subjects Imbibing values & social skills</li>
                            </ul>








                            <br />


                        </div>











                    </div>
                </div>
            </div>



            {/* <PageListSection data={data} /> */}
        </>
    );
};

export default NEP; 