// packages
import { useLayoutEffect } from "react";

// components
import TopSection from "../components/TopSection";
import PageListSection from "../components/PageListSection";

// images
import ActivitesBgPhoto from "../images/safe_campus.jpg";
import Pedagogy from "../images/stem-2.jpeg";

import NCC from "../images/ncc_2 (1).jpg";
import Scouts from "../images/scouts.jpg";
import SportsClub from "../images/sports-club.jpg";
import InterHgsComp from "../images/inter-hgs-comp.jpg";
import SchoolBand from "../images/school_band_2.jpeg";
import bcurri from "../images/bcurri-icon.svg";
import AlumniList from "./AlumniList";

import anti from "../images/anti.jpg";
import Value from "../images/Value.jpg";
import Social from "../images/Social.jpg";
import budding from "../images/budding.jpg";
import gender from "../images/gender.jpg";
import expression from "../images/expression.jpg";
import comm from "../images/comm.jpg";
import env from "../images/env.jpg";
import sports_club from "../images/sports_club.jpg";
import health from "../images/health.jpg";

import green from "../images/green.JPG";
import red from "../images/red.JPG";
import blue from "../images/blue.JPG";
import yellow from "../images/yellow.JPG";

const BeyoundCurriculum = () => {
  const data = [
    {
      icon: bcurri,
      iconMargin: "0 0.5rem 0 0 ",
      heading: "Beyond Curriculum",
      image: null,
      isIntroPara: true,
      padding: ".5rem",
      headingColor: "#f91984",
      headerStripe: false,
    },
    {
      heading: "Clubs",
      image: null,
      content:
        "The school has varied clubs which help reach and accomplish the vision and mission that the school stands by. The values and ethos of the school are promoted and imbibed through these clubs. The clubs are headed by the club in-charges. They have members who understand and teach and who help in facilitating the work and the activities that the club undertakes at school.",
      isIntroPara: true,
      padding: "0rem",
    },
  ];

  const clubs_cards_data = [
    {
      image: anti,
      name: "Anti-Bullying Club",
    },
    {
      image: Value,
      name: "Value Club",
    },
    {
      image: Social,
      name: "Social Impact Club",
    },
    {
      image: budding,
      name: "Budding Authpors' Club",
    },
    {
      image: gender,
      name: "Gender Sensitivity Club",
    },
    {
      image: expression,
      name: "Expression Club",
    },
    {
      image: comm,
      name: "Communication Club",
    },
    {
      image: env,
      name: "Environment Club",
    },
    {
      image: sports_club,
      name: "Sports Club",
    },
    {
      image: health,
      name: "Health Club",
    },
  ];
  const house_data = [
    {
      heading: "House System",
      image: null,
      isIntroPara: true,
      padding: ".5rem",
      content:
        "The schools have four houses. The houses are allotted to the children when they join the school. They remain in the same house till they are a part of the school. The schools organize inter-house competitions in the field of science, computer science, technology, arts, sports, music, and dance. These competitions facilitate healthy competition and generate a house spirit and loyalty among the students.",
    },
  ];
  const house_cards_data = [
    {
      image: blue,
    },
    {
      image: yellow,
    },
    {
      image: red,
    },
    {
      image: green,
    },
  ];

  const style = {
    height: "250px ",
    boxShadow: "rgb(0 0 0 / 25%) 10px 8px 4px",
  };
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <TopSection
        // title="Co-Curricular Activities"
        backgroundImage={Pedagogy}
        bgImageGradient={0}
        // quote="If we wish for our nature to be free and joyous, we should bring our activities into the same order."
        // author="Vinoba Bhave"
      />
      <PageListSection data={data} />
      <AlumniList data={clubs_cards_data} style={style} />
      <PageListSection data={house_data} />
      <AlumniList data={house_cards_data} style={style} />
    </>
  );
};

export default BeyoundCurriculum;
