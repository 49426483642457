import { Link } from "react-router-dom";
import FooterImage from "../images/footer.jpg";
import FooterImage_1 from "../images/footer_1.jpg";
import FooterImage_2 from "../images/Bottom page.png";
import PhoneLogo from "../images/phone.png";
import LocationLogo from "../images/location.png";
import MailLogo from "../images/mail.png";
import MainLogo from "../images/sos_logo.png";
import EducationLogo from "../images/education-logo-white.png";
import TwitterLogoLarge from "../images/twitter-logo-new.png";
import FacebookLogoLarge from "../images/facebook-logo-new.png";
import InstagramLogoLarge from "../images/instagram-logo-new.png";
import { NavLink } from "react-router-dom";
import "./Footer.css"
// import SocialMedias from "./SocialMedia";

const Footer = () => {
    return (
        <>
        <div
            className="w-full text-white flex flex-col bg-no-repeat bg-cover bg-center footerbg"
            // style={{ background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${FooterImage_2})` }}
        >
            <div className="flex flex-col md:grid md:pt-28 md:pb-36 px-8 py-16 lg:px-30  md:grid-cols-2 gap-16 md:gap-4">
                <div className="flex flex-col justify-center gap-3">
                    {/* <div className="h-24 mb-12">
                        <img className="h-full" src={MainLogo} alt="" />
                    </div> */}
                    <div
                        className="font-semibold flex gap-4 items-center"
                        to="#"
                    >
                        <img className="w-6" src={PhoneLogo} alt="" />
                        <div>011 4335 7312</div>
                    </div>
                    <div
                        className="font-semibold flex gap-4 items-center"
                        to="#"
                    >
                        <img className="w-6" src={LocationLogo} alt="" />
                        <div>
                            A-7, Block A, Nizamuddin West, New Delhi, Delhi
                            110013
                        </div>
                    </div>
                    <div
                        className="font-semibold flex gap-4 items-center"
                        to="#"
                    >
                        <img className="w-6" src={MailLogo} alt="" />
                        <div>communication.school@hermann-gmeiner-edu.org</div>
                    </div>
                    
                    {/* <Link
                        className="font-semibold flex gap-4 items-center hover:underline"
                        to="/about-us"
                    >
                        <img className="w-6" src={EducationLogo} alt="" />
                        <div>About Us</div>
                    </Link> */}
                </div>
                {/* <div className="flex flex-col gap-8"> */}
                {/* <div className="font-bold text-sm tracking-widest">
                        Our Socials
                    </div>
                    <div className="font-bold  flex gap-8 md:justify-start md:gap-16 text-sm md:text-base flex-wrap">
                        <Link
                            to="#"
                            className="flex flex-col gap-4 justify-center items-center"
                        >
                            <div className="h-10 md:h-12">
                                <img
                                    className="h-full"
                                    src={FacebookLogoLarge}
                                    alt=""
                                />
                            </div>
                            <div className="">Facebook</div>
                        </Link>
                        <Link
                            to="#"
                            className="flex flex-col gap-4 justify-center items-center"
                        >
                            <div className="h-10 md:h-12">
                                <img
                                    className="h-full"
                                    src={InstagramLogoLarge}
                                    alt=""
                                />
                            </div>
                            <div className="">Instagram</div>
                        </Link>
                        <Link
                            to="#"
                            className="flex flex-col gap-4 justify-center items-center"
                        >
                            <div className="h-10 md:h-12">
                                <img
                                    className="h-full"
                                    src={TwitterLogoLarge}
                                    alt=""
                                />
                            </div>
                            <div className="">Twitter</div>
                        </Link>
                    </div> */}
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.2961319564647!2d77.24220307393708!3d28.590891685973297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce30337f0128b%3A0x33a0c46b5a1c6f42!2sSOS%20CHILDREN&#39;S%20VILLAGES%20INDIA!5e0!3m2!1sen!2sbd!4v1687801581841!5m2!1sen!2sbd" className="rounded" width="500" height="250" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                {/* </div> */}
            </div>
            {/* <div className="text-gray-500 bg-white text-xs md:text-base flex justify-between gap-4 items-center font-bold py-4 px-4 md:px-40">
                <span>Made With Love By KyloApps All Right Reserved</span>
                <SocialMedias
                    facebookLink="https://www.facebook.com/kyloapps"
                    instagramLink="https://instagram.com/kyloappsglobal"
                    twitterLink="https://twitter.com/kyloapps"
                />
            </div> */}
        </div>
        <div className="footer_main">

        
          <div className="flex-1">
          <div className="f-2 f-400 mx-5"> QUICK LINKS</div>
          <div className="footer-section">
            <div className="footer-section-1">
              <a href="https://hermanngmeinereducationalinstitutions.org/about-the-institution">
                <div className="f-1-2 fs-6 hover cursor f-500 mt-2 fs-small">
                  About Hermann Gmeiner Educational Institutions
                </div>
              </a>
  
              <NavLink to={"/nep"}>
                <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                  Academics & Curriculum
                </div>
              </NavLink>
            </div>
            <div className="footer-section-2">
              <NavLink to={"/house-clubs"}>
                <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                  Beyond Curriculum
                </div>
              </NavLink>
              <NavLink to={"/news"}>
                <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                  News & Media Coverage
                </div>
              </NavLink>
            </div>
            <div className="footer-section-3">
              <NavLink to={"/infrastructure"}>
                <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                  {" "}
                  Infrastructure & Facilities
                </div>
              </NavLink>
              <NavLink to={"/contact-us"}>
                <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                  {" "}
                  Contact Us
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        </div>
        </>
    );
};

export default Footer;
