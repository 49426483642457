// packages
import { useLayoutEffect } from "react";

// components
import AboutUsList from "../components/AboutUsList";
import TopSection2 from "../components/TopSection2";
import Mission from "../components/Mission";
import AboutTheInstitute from "../components/AboutTheInstitute";

// images
import AboutUsBg from "../images/56_cover.jpg";
import campusImage from "../images/a.jpg";
import campusHome from "../images/campusHome.jpg";
import TopSection from "../components/TopSection";
import HermannGmeiner from "../components/HermannGmeiner";

const data = [
    {
        heading: "About Us",
        // image: campusImage,
        content: `Hermann Gmeiner Educational Institutions under the trust of SOS Children’s Village launched its first school in 1986 in Faridabad, aiming to provide meticulous attention to detail in creating a tuition-free education. The focus is on holistic development, encompassing life skills education, STEM education, coding, robotics, and financial literacy. To foster transformative student life experiences, the institutions utilize technology-driven classes and offer complimentary counseling sessions to assess both mental and physical well-being. All our schools adhere to the National Education Policy 2020 and are equipped with STEM Labs, Computer Labs, and the necessary infrastructure, including extensive sports facilities. All Hermann Gmeiner Institutions are self-sustaining, promoting 21st-century skills and fostering global citizenship.`,
        content2: `We strive to inspire positive change and cultivate transformative future leaders. Our commitment to academic rigor and innovative learning experiences allows students to embark on an exceptional educational journey with us.
        Today, we operate four Senior Secondary CBSE Schools located in Faridabad, Jammu, Varanasi, and Bhimtal. The Hermann Gmeiner School in Bhimtal also offers day and boarding options. Additionally, we have four State Board Schools: Hermann Gmeiner School in Bhopal (Senior Secondary School), Hermann Gmeiner School in Palla Nuh (Senior Secondary School), and Hermann Gmeiner Schools in Bhuj and Anangpur (Primary Schools). Beyond traditional courses, we have a Nursing School in Anangpur and a Degree College in Bhimtal. To enhance employability skills, we have successfully established Vocational Training Centres in Nizamuddin, Raipur, and Bhakatpara. Moreover, Vocational Training Centres and after-school classes are now a part of all Hermann Gmeiner Schools.`,
    },
];

const AboutTheInstitutionsPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <>
            <TopSection2
                title="About the Institution"
                backgroundImage={campusHome}
                bgImageGradient={0}
            />
            <HermannGmeiner />
            <AboutTheInstitute data={data} />
            <Mission />
        </>
    );
};

export default AboutTheInstitutionsPage;
