import PhotoFrameHorizontal from "../images/photoframe.png";
import alumni1 from "../images/user-cover-2.jpg";
// import { useLocation } from "react-router-dom";

const AwardsListSection = ({ data, limit }) => {
    if (data === undefined) {
        data = [
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
        ];
    }
    if (limit === undefined) {
        limit = data.length;
    }
    // let location = useLocation();
    return (
        <div className="w-full px-12 pb-32 md:px-20 md:pb-40">
            {/* <div className="max-w-full justify-items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 mt-20 gap-16"> */}
            <div className="max-w-full justify-items-center flex flex-wrap justify-center mt-20 gap-12">
                {data.map((award, index) => {
                    return (
                        <div
                            data-aos="zoom-in"
                            data-aos-delay={600}
                            // className="flex flex-col overflow-hidden rounded-lg shadow-xl w-[370px] bg-no-repeat box-border px-[23px] py-[45px] md:px-[30px] md:py-[43px]"
                            className="flex flex-col overflow-hidden rounded-lg shadow-xl w-[370px] bg-no-repeat box-border"
                            key={index}
                            // style={{
                            //     backgroundImage: `url(${PhotoFrameHorizontal})`,
                            //     backgroundSize: "100% 100%",
                            // }}
                        >
                            <div className="h-[300px] w-full overflow-hidden bg-cover bg-center bg-no-repeat flex items-stretch">
                                <img className="w-full" src={award} alt="" />
                            </div>
                            <div className="">
                                {/* <div
                                    // className="h-[210px] w-full overflow-hidden bg-cover bg-center bg-no-repeat flex items-stretch border-b border-[#b9b9b9]"
                                    className="h-[300px] w-full overflow-hidden bg-cover bg-center bg-no-repeat flex items-stretch"
                                    // style={{
                                    //     backgroundImage: `url(${award})`,
                                    // }}
                                >
                                    <img src={award} alt="" />
                                </div> */}
                                {/* <div className="flex flex-col items-center text-center p-2">

                                    <div className="font-semibold">
                                        {award.name}
                                    </div>
                                    <div className="text-[#4d4d4d] text-sm">
                                        {award.school}
                                    </div>
                                    <div className="text-[#737373] text-sm">
                                        {award.date}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AwardsListSection;
