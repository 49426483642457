// packages
import { useLayoutEffect } from "react";

// components
import TopSection2 from "../components/TopSection2";
import AlumniList from "../components/AlumniList";

// images
import bhim_3 from "../images/MediaCoverage2/13.png";
import bhim_4 from "../images/MediaCoverage2/14.png";
import bhim_5 from "../images/MediaCoverage2/15.png";
import bhim_6 from "../images/MediaCoverage2/16.png";
import bhim_7 from "../images/MediaCoverage2/17.png";
import bhim_8 from "../images/MediaCoverage2/18.png";
import bhim_9 from "../images/MediaCoverage2/19.png";
import bhim_11 from "../images/MediaCoverage2/20.png";
import bhim_13 from "../images/MediaCoverage2/21.png";
import bhim_15 from "../images/MediaCoverage2/22.png";
import bhopal_1 from "../images/MediaCoverage2/23.png";
// import bhopal_2 from "../images/bhopal2.jpg";
// import fbd_1 from "../images/fbd1.jpg";
// import fbd_2 from "../images/fbd2.jpg";
// import fbd_3 from "../images/fbd3.jpg";
// import fbd_4 from "../images/fbd4.jpg";
// import jammu_1 from "../images/jammu1.jpg";
// import jammu_2 from "../images/jammu2.jpeg";
// import jammu_3 from "../images/jammu3.jpg";
// import jammu_4 from "../images/jammu4.jpg";
// import varanasi_1 from "../images/varanasi1.jpg";
// import marathon1 from "../images/marathon1.jpg";
// import marathon2 from "../images/marathon2.jpg";
// import marathon3 from "../images/marathon3.jpg";
// import marathon4 from "../images/marathon4.jpg";
// import marathon5 from "../images/marathon5.jpg";
import InfrastructureBgPhoto from "../images/media_coverage_cover.jpg";

const MediaCoveragePage = () => {
    const data = [
        // {
        //     //   name: "Inter Hermann Gmeiner School Art Exhibition",
        //     image: marathon1,
        //     description: `Hermann Gmeiner School, Varanasi`,
        // },
        // {
        //     //   name: "Inter Hermann Gmeiner School Art Exhibition",
        //     image: marathon2,
        //     description: `Hermann Gmeiner School, Varanasi`,
        // },
        // {
        //     //   name: "Inter Hermann Gmeiner School Art Exhibition",
        //     image: marathon3,
        //     description: `Hermann Gmeiner School, Varanasi`,
        // },
        // {
        //     //   name: "Inter Hermann Gmeiner School Art Exhibition",
        //     image: marathon4,
        //     description: `Hermann Gmeiner School, Varanasi`,
        // },
        // {
        //     //   name: "Inter Hermann Gmeiner School Art Exhibition",
        //     image: marathon5,
        //     description: `Hermann Gmeiner School, Varanasi`,
        // },
        {
            //   name: "Science Model Demonstration",
            image: bhim_3,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "Special assembly with IPS, DCP Dr. Arpit Jain",
            image: bhim_4,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "Virtual Camp on Coping with Lockdown",
            image: bhim_5,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "Impressive Times",
            image: bhim_6,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "Dainik Jagran",
            image: bhim_7,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "Impressive Times",
            image: bhim_8,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "Dainik Savera",
            image: bhim_9,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "TOI",
            image: bhim_11,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "TOI",
            image: bhim_13,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "Punjab Kesari",
            image: bhim_15,
            description: "Hermann Gmeiner School, Bhimtal",
        },
        {
            //   name: "Impressive Times",
            image: bhopal_1,
            description: "Hermann Gmeiner School, Bhopal",
        },
        // {
        //     //   name: "TOI",
        //     image: bhopal_2,
        //     description: "Hermann Gmeiner School, Bhopal",
        // },
        // {
        //     //   name: "TEACHER'S DAY",
        //     image: fbd_1,
        //     description: "Hermann Gmeiner School, Faridabad",
        // },
        // {
        //     //   name: "ROAD SAFETY DAY",
        //     image: fbd_2,
        //     description: "Hermann Gmeiner School, Faridabad",
        // },
        // {
        //     //   name: "Hermann Gmeiner School TOPPERS",
        //     image: fbd_3,
        //     description: "Hermann Gmeiner School, Faridabad",
        // },
        // {
        //     //   name: "ORIENTATION DAY",
        //     image: fbd_4,
        //     description: "Hermann Gmeiner School, Faridabad",
        // },
        // {
        //     //   name: "EARTH DAY CELEBRATION",
        //     image: jammu_1,
        //     description: "Hermann Gmeiner School, Jammu",
        // },
        // {
        //     //   name: `Workshop on "the magic of youth"`,
        //     image: jammu_2,
        //     description: `Hermann Gmeiner School, Jammu`,
        // },
        // {
        //     //   name: "TEACHING EXCELLENCE AWARD",
        //     image: jammu_3,
        //     description: `Hermann Gmeiner School, Jammu`,
        // },
        // {
        //     //   name: "DUSSEHRA CELEBRATION",
        //     image: jammu_4,
        //     description: "Hermann Gmeiner School, Jammu",
        // },
        // {
        //     //   name: "Inter Hermann Gmeiner School Art Exhibition",
        //     image: varanasi_1,
        //     description: `Hermann Gmeiner School, Varanasi`,
        // },
    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <TopSection2
                title="Media Coverage"
                backgroundImage={InfrastructureBgPhoto}
                bgImageGradient={0}
            />
            <AlumniList data={data} />
        </>
    );
};

export default MediaCoveragePage;
