import Background from "../images/school-image.jpg";

const TopSection2 = ({
    title,
    backgroundImage = Background,
    bgImageGradient = 0,
}) => {
    var backgroundImageClass = ``;

    if (bgImageGradient > 0) {
        backgroundImageClass += `linear-gradient( rgba(0, 0, 0, ${bgImageGradient}), rgba(0, 0, 0, ${bgImageGradient}) ), `;
    }

    backgroundImageClass += `url(${backgroundImage})`;

    return (
        <div
            data-aos="fade"
            className="w-full p-20 h-[80vh] md:h-[95vh] flex items-center bg-no-repeat bg-cover bg-top"
            style={{
                backgroundImage: `${backgroundImageClass}`,
            }}
        >
            <span
                data-aos="fade-right"
                data-aos-delay={300}
                className="title-textShadow text-white text-3xl md:text-5xl font-bold tracking-wide drop-shadow-md"
            >
                {title}
            </span>
        </div>
    );
};

export default TopSection2;
