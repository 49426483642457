// packages
import { useLayoutEffect } from "react";

// components
import PageListSection from "../components/PageListSection";
import TopSection from "../components/TopSection";

// images
import InfrastructureBgPhoto from "../images/home-background.jpeg";
import CampusPhoto from "../images/campus.jpg";
import ClassroomPhoto from "../images/classroom.jpg";
import StemLabPhoto from "../images/stem_lab.jpg";
import ComputerLabPhoto from "../images/computer-lab.jpg";
import ScienceLabPhoto from "../images/science_lab.jpg";
import LivingScienceLabPhoto from "../images/living-science-lab.jpg";
import LibraryLabPhoto from "../images/library.jpg";
import HomeScienceLabPhoto from "../images/home_science.jpg";
import PlaygroundPhoto from "../images/playground.jpg";
import HealthClub from "../images/health_club_2.jpg";
import Auditorium from "../images/auditorium.jpg";

const InfrastructurePage = () => {
  const data = [
    {
      heading: "Sprawling Campus",
      image: CampusPhoto,
      // content: `With a huge, sprawling campus, we promise you a space where kids can play,
      //           learn and just be themselves. Keeping the safety and well-being of every child
      //           in mind, we've created a safe space for everyone to grow and develop in every
      //           aspect.`,
      content: `With huge, sprawling campuses, we promise you a space where kids can play, learn and just be themselves. Keeping the safety and well-being of every child in mind, we've created safe spaces for everyone to grow and develop in all aspects. HGS Bhimtal is spread across 42 acres of land, HGS Jammu has 7 acres and HGS Varanasi has a total of 6 acres of land area which ensures that all needs of the students are taken care of.`,
    },
    {
      heading: "Spacious Child Friendly Interactive Classrooms",
      image: ClassroomPhoto,
      content: `The school equip spacious, well ventilated, and complete and affable furnished 
                      classrooms for all the students to be comfortable and poised. Special storage 
                      units are also available for students to keep their bags and other academic 
                      necessities.`,
    },
    {
      heading: "STEM Lab",
      image: StemLabPhoto,
      content: `Special STEM labs, ie science, technology, engineering, and mathematics labs 
                      for every prodigy to polish their skills and gain knowledge and experience with 
                      all available equipment and help.`,
    },
    {
      heading: "Computer Lab",
      image: ComputerLabPhoto,
      content: `Keeping up with the latest technologies and compute learning, the school provide 
                      advanced computers with perfect WiFi service for students to learn and develop 
                      these prior skills. We also help students to be familiar with and have expertise 
                      in coding and programming.`,
    },
    {
      heading: "Living Science Lab",
      image: LivingScienceLabPhoto,
      content: `The living science lab provides knowledge and live experience in many fields 
                      like science, life evolution, biotechnology, bio-genetics, medicine and 
                      health. This gives students the opportunity to have literal experience and 
                      information about life and genetics. Special scientific equipment, printed 
                      and virtual information and teachers are always available for the students.`,
    },
    {
      heading: "Science Lab: Physics, Chemistry, Biology, General",
      image: ScienceLabPhoto,
      content: `Perfectly adjusted temperature and nurtured science labs with full furniture 
                      and equipment for all students to enhance their scientific skills and research. 
                      Physics, chemistry, and biology all 3 subjects have differentiated labs for 
                      students to work in a team and individually.`,
    },
    {
      heading: "Library",
      image: LibraryLabPhoto,
      content: `With a huge collection of books, novels, newspapers, periodicals, and much 
                      more, we provide the students with over 10,000 different reading syllabuses. 
                      Informative prints like encyclopaedias, dictionaries, and personality 
                      development books are also accessible to all in all our schools. The students 
                      and teachers also have access to all the digital information available at 
                      schools. Books, documents, PDFs, question papers and examination forms, all 
                      are accessible in the digital library of the schools.`,
    },
    {
      heading: "Health Club",
      image: HealthClub,
      content: `We believe in the health and wellness of the students and to ensure that, 
                      we have established the health club. This health club not only teaches them 
                      about the benefits of yoga and a healthy lifestyle but also organises fun 
                      seminars to keep them engaged. Specially trained yoga teachers and instructors 
                      help the students to stay in healthy shape whereas zumba and aerobics keep 
                      them fresh and cheerful.`,
    },
    {
      heading: "Home Science",
      image: HomeScienceLabPhoto,
      content: `The school provides exclusive and we'll equip home science labs to all the 
                      students where they learn the art of food, fashion, finance, and family 
                      development.`,
    },
    {
      heading: "Auditorium",
      image: Auditorium,
      content: `Large, spacious, and well-furnished auditoriums are always open and available 
                      for gatherings and events. We often hold seminars and other gatherings for 
                      students and teachers and many indoor competitions also take place here. 
                      We have a huge capacity to hold and entertain a large audience.`,
    },
    {
      heading: "Play Ground",
      image: PlaygroundPhoto,
      content: `Just like every theoretical and practical subject, sports are our top most 
                      priority as well. Playgrounds with required nets and goalposts, all gaming 
                      equipment, and ground not very hard or mushy, everything is available for kids 
                      to play and enjoy.`,
    },
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <TopSection
        title="Infrastructure"
        backgroundImage={InfrastructureBgPhoto}
        bgImageGradient={0}
        quote="Infrastructure development is economic development."
        author="Kay Ivey"
      />
      <PageListSection data={data} />
    </>
  );
};

export default InfrastructurePage;
