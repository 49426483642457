
// const Results =()=>{

//     const pdfFiles = [
//         { name: ' Hermann Gmeiner School Bhopal', url : '../documents/RESULT_HERMANN GMEINER SCHOOL, BHOPAL.xlsx'},
//         { name: ' Hermann Gmeiner School Bhimtal', url: '../documents/RESULT_HERMANN GMEINER SCHOOL, BHIMTAL.xlsx'},
//         // { name: ' Hermann Gmeiner School Anangpur', url: },
//         { name: ' Hermann Gmeiner School Palla Nuh', url: '../documents/RESULT_HERMANN GMEINER SCHOOL, PALLA.xlsx' },
//         { name: ' Hermann Gmeiner School Varansi', url:'../documents/RESULT_HERMANN GMEINER SCHOOL, VARANASI.xlsx'},
//         { name: ' Hermann Gmeiner School Jammu', url:'../src/documents/RESULT_HERMANN GMEINER SCHOOL, JAMMU.xlsx' },
//         { name: ' Hermann Gmeiner School Faridabad', url: '../documents/RESULT_HERMANN GMEINER SCHOOL, FARIDABAD.xlsx'},
//     ]
//     return (
//         <div className="home_main">
//           <div className="pdf-container">
//           <table className="pdf-table">
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>School Name</th>
//                 <th>Links</th>
//               </tr>
//             </thead>
//             <tbody>
//               {pdfFiles.map((file, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{file.name}</td>
//                   <td>
//                     <a href={file.url} download target="_blank" rel="noopener noreferrer">
//                       View
//                     </a>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
    
//         </div>
//       );
// }

// export default  Results;

import bhopal from '../documents/RESULT_HERMANN GMEINER SCHOOL, BHOPAL.xlsx';
import bhimtal from '../documents/RESULT_HERMANN GMEINER SCHOOL, BHIMTAL.xlsx';
import palla from '../documents/RESULT_HERMANN GMEINER SCHOOL, PALLA.xlsx';
import varanasi from '../documents/RESULT_HERMANN GMEINER SCHOOL, VARANASI.xlsx';
// import jammu from '../src/documents/RESULT_HERMANN GMEINER SCHOOL, JAMMU.xlsx';
import faridabad from '../documents/RESULT_HERMANN GMEINER SCHOOL, FARIDABAD.xlsx';

const Results = () => {

  const pdfFiles = [
    { name: 'Hermann Gmeiner School Bhopal', data: bhopal },
    { name: 'Hermann Gmeiner School Bhimtal', data: bhimtal },
    // { name: ' Hermann Gmeiner School Anangpur', url: '' }, // Empty url commented out
    { name: 'Hermann Gmeiner School Palla Nuh', data: palla },
    { name: 'Hermann Gmeiner School Varansi', data: varanasi },
    // { name: 'Hermann Gmeiner School Jammu', data: jammu },
    { name: 'Hermann Gmeiner School Faridabad', data: faridabad },
  ];

  return (
    <div className="home_main">
      <div className="pdf-container">
        <table className="pdf-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>School Name</th>
              <th>Links</th>
            </tr>
          </thead>
          <tbody>
            {pdfFiles.map((file, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{file.name}</td>
                <td>
                  <a href={file.data} download target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};











export default Results;
