// packages
import { useLayoutEffect } from "react";

// containers
import TopSection2 from "../components/TopSection2";
import PageListSection from "../components/PageListSection";
import Phone from "../images/phone.png";
import PedagogiSkill from "./PedagogiSkill";
// images
import CareersCover from "../images/careers_cover.jpg"
import topicon from "../images/pedagogyTopIcon.svg";

const Skillvocational = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });


    return (
        <>
            {/* <TopSection2
        // title="Pedagogy"
        backgroundImage={CareersCover}
        bgImageGradient={0}
      // quote="Children must be taught how to think, not what to think."
      // author="Margaret Mead"
      /> */}

            <div className="w-full h-full px-12 md:px-20 py-6 snap-center">
                <div className="flex">
                    {/* <img
            className="pedagogi_image"
            src={topicon}
            alt=""
            style={{ width: "80px" }}
          /> */}
                    <h1 className="text-4xl mt-4 font-semibold text-[#1fdc00d2]">

                        Skill and Vocational Subjects
                    </h1>
                </div>
                <div className="flex gap-2">
                    <div>
                        <div className="text-[#000000b8]  text-1xl mx-6 my-12">

                        </div>
                        <div className="text-[#000000c4]  mx-6 my-8">


                        </div>
                        {/* <div className=" my-15 flex justify-center">
              <img className="mb-16" src={p1} />
            </div> */}
                        <div className="text-[#000000c4] font-medium mx-6 my-4">


                            {/* <br /> */}
                        </div>

                        <div className="text-[#000000c4]  mx-6 my-4 ">
                            {/* <h2 className="font-semibold">Areas of Responsibility:</h2> */}

                            {/* <h3 className="font-semibold">Position Purpose (Mission):</h3> */}
                            Vocational subjects include more hands-on training and less of the traditional academic learning. We at Hermann Gmeiner School focus on integrating art with the curriculum for all the students to appreciate the aesthetics and visual performing arts. Practical or first-hand knowledge about the market or trade is a very essential part of the vocational training that we provide to the learners.We always prioritize our students and for that we offer differentiated teaching-learning activities as well as personalized learning. All the varied Skill and Vocational subjects are included and taught at our institution. A team of dignified and intellectual teachers is always on the stand to help the students master the subjects.Emulating the skill-based model of education as per NEP 2020 and CBSE guidance, we have also introduced the skill subjects at the secondary level. Choosing any one Skill subject at this level can help the child to pursue what truly interests or pleases him or her. This liberty promotes a sense of self-esteem in accepting one’s talents and strengths.


                            <br />
                            <h3></h3>

                            <br />
                            <h4 className="font-semibold">Classes VI-VIII</h4>
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li>Mass Media</li>
                                <li>Financial Literacy</li>
                                <li>Handicraft</li>
                            </ul>
                            <br/>
                            <h4 className="font-semibold">
                                Classes IX-X</h4>
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li>Information Technology

                                </li>
                                <li>Banking & Insurancey</li>
                                <li>Introduction to Tourism</li>
                            </ul>

                            <br />
                            <h4 className="font-semibold">Classes XI-XII</h4>
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li>Web Application</li>
                                <li>Financial Literacy</li>
                                <li>Mass Media</li>
                            </ul>

                            <br />


                        </div>











                    </div>
                </div>
            </div>



            {/* <PageListSection data={data} /> */}
        </>
    );
};

export default Skillvocational; 