// packages
import { useLayoutEffect } from "react";

// components
import TopSection from "../components/TopSection";
import PageListSection from "../components/PageListSection";
import PhotoGallery from "../components/PhotoGallery";

// images
import BgPhoto from "../images/social-imact-programme-large.jpg";
import SocialImactProgrammeCollab from "../images/social-imact-programme-collab.jpg";
import ClimateChange from "../images/climate-change.jfif";
import Sports from "../images/sports.jpg";
import GenderEquality from "../images/gender-equality.jpeg";
import WomenLiteracy from "../images/women-literacy.jpeg";
import SelfDefence from "../images/self-defence.jpg";

const SocialImpactPage = () => {
    const data = [
        {
            heading: "Social Impact Programmes",
            image: SocialImactProgrammeCollab,
            content: `We conduct Social Impact Programmes by addressing pressing social issues 
                      and helping initiate a positive change in the organization as well the 
                      society. We have organized several programs on Women's Adult literacy, 
                      Self-defence training, Global Warming, Safety from Covid-19, etc.`,
        },
        {
            heading: "Climate Action",
            image: ClimateChange,
            content: `Campaigns and programmes were conducted to spread awareness to more locals 
                      and students about CLIMATE ACTION. Climate action means performing and 
                      understanding various ways to reduce the emission of greenhouse gas and 
                      strengthening resilience and adaptation to various climate related problems. 
                      This helps everyone to understand the seriousness and sensitivity of the 
                      issue and teach the young ones to bringin the changes from an early stage.`,
        },
        {
            heading: "Gender Equality",
            image: GenderEquality,
            content: `Gender Equality is yet again a very crucial topic that kids should know 
                      and learn about from a young age. Discrimination based on gender and gender 
                      card, topics like this should be taught to students and the impact they have 
                      on society. Fun but substantial sessions and events are held which help the 
                      students to understand the roles of men and women in the society and their 
                      importance.`,
        },
        {
            heading: "Women Adult Literacy",
            image: WomenLiteracy,
            content: `To inform the students about the importance and people’s ignorance regarding 
                      Women Literacy, the difference between male to female literacy percentage was 
                      presented using charts in seminars. It is very crucial for younger generations 
                      to know about the vulnerability of such topics and work on them later. Not just 
                      lectures but dramas and poems are also used to grasp the attention of younger 
                      students and make them realise the importance of such issues.`,
        },
        {
            heading: "Self-Defence Training",
            image: SelfDefence,
            content: `Self-defence training,especially for girls, is very important in today’s 
                      time. To make the female batch aware about the sinister thinking of some 
                      and how to defend yourself in one such situation is the main objective of 
                      this program. How to physically defend oneself, how to act if one is stuck 
                      in such a situation and how to help a friend, all of this is taught to the 
                      students.`,
        },
        {
            heading: "Good Health And Well-Being",
            image: Sports,
            content: `Fitness and good health are very important in a child’s growth and to make 
                      sure all of our students are following the steps of fitness and health, this 
                      program was conducted. Many physically and mentally challenging exercises were 
                      performed by the students and special lectures were given to inform them about 
                      good health and well-being. Students who perform better than the rest are 
                      appreciated to encourage them and every child to give their best.`,
        },
    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <TopSection
                title="Social Impact Programmes"
                backgroundImage={BgPhoto}
                quote="Learning is not the product of teaching, it is the product of the activity of learners."
                author="John Holt"
            />
            <PageListSection data={data} />
            <PhotoGallery />
        </>
    );
};

export default SocialImpactPage;
